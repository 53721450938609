class FormTemplateNewsletter extends BATComponent {

	beforeLoad() {
		//
	}

	afterLoad() {
		this.$el = $(this);

		Forms.loadFormJSON(this.data).then((response) => {
			$(this).attr('data-model', JSON.stringify(response));
		});

		
		let url_string = window.location;
		let url = new URL(url_string);
		let bool_showNewsletter = url.searchParams.get("showNewsletter");
		if (bool_showNewsletter == "true") {
			setTimeout(() =>{
				$(`#news-letter-signup`).addClass('active')
									.trigger('modal:open');
			},2000)
		}
		
	}

	beforeUpdate() {
		//
	}

	afterUpdate() {
		this.bindEvents();
		this.enableCtaSubmit();
		this.populateYears(1900);
	}

	enableCtaSubmit(){
		const submitButton = this.$el.find(".bat-form-field.bat-form--newsletter-email-submit button");
		const step1Form = this.$el.find("#step1Form");
		step1Form.change(function() {
    		if($('input[name="newsletter-checkbox"]')[1].checked && $('input[name="policy-checkbox"]')[1].checked && step1Form[0].checkValidity()){
				submitButton.removeAttr("disabled");
            }else{
				submitButton.attr("disabled","");
			}
		});
	}

	bindEvents() {
		//
		const $forms = this.$el.find('form');
		const $step1 = this.$el.find('#step1');
		const $step1Form = this.$el.find('#step1Form');
		const $step2 = this.$el.find('#step2');
		const $step3 = this.$el.find('#step3');
		const $labelToggle = this.$el.find('.label-toggle');
		const $step1FormButton = $step1Form.find('button[type="submit"]');
		const $step1FormSubmitStatus = $step1Form.find('.submit-status');
		const $disclaimer = this.$el.find('.form-disclaimer');

		if (Utils.getCookie('commerce-auth-verify') === 'true') {
			const successP = $("#newsletter-success-p")
			if(successP.length > 0)
				successP[0].innerHTML = "Sei già registrato! Puoi rivedere le tue impostazioni <a href='/it/it/myaccount/account/edit-preferences'>qui</a>.";
			$step1.removeClass('active');
			$step2.addClass('active');
			$disclaimer.addClass('hidden');
			return;
		}

		$step1Form.submit((e) => {
			e.preventDefault();

			if (!this.checkAge()) {
				const errorYear = document.querySelector(".error-year");
				const errorMonth = document.querySelector(".error-month");
				const errorDay = document.querySelector(".error-day");

				errorYear.innerHTML = "Devi essere maggiorenne per poterti iscrivere"
				errorMonth.innerHTML = "Devi essere maggiorenne per poterti iscrivere"
				errorDay.innerHTML = "Devi essere maggiorenne per poterti iscrivere"
				
				errorYear.closest(".bat-message.bat-form-msg.error-msg.error").style.display = "block"
				errorMonth.closest(".bat-message.bat-form-msg.error-msg.error").style.display = "block"
				errorDay.closest(".bat-message.bat-form-msg.error-msg.error").style.display = "block"

				return;
			}

			$step1FormButton.css('display', 'none');
			$step1FormSubmitStatus.css('display', 'block');

			Commerce.guestSubscribeNewsletter(this.parseForm()).then((response) => {
				$step1.removeClass('active');

				if (this.checkResponse(response)) {
					$step2.addClass('active');
					$disclaimer.addClass('hidden');
				} else {
					$step3.addClass('active');
					$step3.find('.newsletter-error p:not(.headline3)').text("I dati inseriti risultano già registrati");
					$disclaimer.addClass('hidden');
				}

				const { pathname } = new URL(window.location.href);
				const pagename = pathname.split('/').pop();
				
				if (typeof dataLayer !== 'undefined') {
					dataLayer.push({
						PageType: pagename,
						Country: 'IT',
						SiteSection: pagename,
						event: 'newsletterSubscribe'
					});
				}

				console.log('response', response);
			});
		});

		$forms.each((index, form) => {
			const $form = $(form);
			Forms.bindFieldEvents($form);
		});

		$labelToggle.click((e) => {
			e.preventDefault();
			e.stopPropagation();
			const temp = $(e.currentTarget).data('alt-text');

			$('.bat-form--newsletter-email-age-verified').toggleClass('open');
			$('.bat-form--newsletter-email-data-handling').toggleClass('open');
			$('.bat-form--newsletter-email-consent-message').toggleClass(
				'open'
			);
			$('.bat-form--newsletter-email-email-opt-in').toggleClass('open');
			$('.bat-form--newsletter-email-mobile-opt-in').toggleClass('open');
			$('.bat-form--newsletter-email-social-media-opt-in').toggleClass(
				'open'
			);
			$(
				'.bat-form--newsletter-email-trial-information-opt-in'
			).toggleClass('open');

			$(e.currentTarget)
				.data('alt-text', $(e.currentTarget).text())
				.text(temp);
		});
	}

	unload() {
		//
	}

	parseForm() {
		const email = $('input[name="emailAddress"]')[0].value;
		const firstname = $('input[name="firstName"]')[0].value;
		const lastname = $('input[name="lastName"]')[0].value;
		const phone = $('input[name="phone"]')[0].value;
		let consentProfiling = $('input[name="profiling-checkbox"]');
		let marketing_by_email = $('input[name="newsletter-checkbox"][value="email"]').is(':checked');
		let marketing_by_sms = $('input[name="newsletter-checkbox"][value="sms"]').is(':checked');
		if(consentProfiling.length > 0) {
			consentProfiling = consentProfiling.is(':checked');
			marketing_by_email = $('input[name="newsletter-checkbox"]').is(':checked');
			marketing_by_sms = $('input[name="newsletter-checkbox"]').is(':checked');
		} else {
			consentProfiling = null;
		}
		const day = $('select[name="regDay"]').val();
		const month = $('select[name="regMonth"]').val();
		const year = $('select[name="regYear"]').val();
		const date = day + "/" + month + "/" + year;

		const form = {
			email: email,
			firstname: firstname,
			lastname: lastname,
			phone: phone,
			marketing_by_email: marketing_by_email,
			marketing_by_sms: marketing_by_sms,
			date_of_birth: date,
			consent_profiling: consentProfiling,
			store_my_data: true
		}

		return form;
	}

	checkResponse(response) {
		if (response && response.data && response.data.guestSubscribeNewsletterMarketing && response.data.guestSubscribeNewsletterMarketing.status == true) {
			return true;
		} else {
			return false;
		}
	}

	populateYears(start) {
		const selectYear = document.querySelector("select[name='regYear']");
		const currentYear = new Date().getFullYear();

		let text = "<option value=''>Anno</option>\n";

		
		for (let i=start; i<=currentYear; i++) {
			text = text + "<option value='"+i+"'>"+i+"</option>\n";
		}

		if (selectYear) {
			selectYear.innerHTML = text;
		}
	}

	checkAge() {
		const day = $('select[name="regDay"]').val();
		const month = $('select[name="regMonth"]').val();
		const year = $('select[name="regYear"]').val();

		const today = new Date();
		const birthDate = new Date();
		birthDate.setFullYear(year);
		birthDate.setMonth(month-1, day);
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		
		if (age>=18) {
			return true;
		} else {
			return false;
		}
	}
}

!customElements.get('bat-form-gloitnewsletter') &&
	customElements.define('bat-form-gloitnewsletter', FormTemplateNewsletter);
